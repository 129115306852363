.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px 0;
  gap: 32px;

  input, textarea {
    width: 70%;
    max-width: 600px;
  }

  select {
    padding: 8px 17px;
    font-family: var(--font-inter);
    border: 1px solid #e9e9e9;
    border-radius: 5px;
  }
}

.errorField {
  color: red;

  input {
    border: 1px red solid !important;
  }
}

.input {
  background: rgb(240 240 240 / 42%);
  border: none;
  border-bottom: 1px solid #ccdbe4;
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  outline: none;
  font-family: var(--font-inter);
  padding-left: 12px;
}

.errorMessage {
  color: red;
  margin-top: 0;
  font-size: .9em;
}

.text {
  background: rgb(240 240 240 / 42%);
  border: none;
  border-bottom: 1px solid #ccdbe4;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  font-family: var(--font-inter);
}
