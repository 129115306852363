.formLinks {
  font-size: 0.9em;
  line-height: 1.8em;
  color: #787878;

  .link {
    color: #787878;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
  }
}

