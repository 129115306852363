.body {
  min-height: 100vh;
  background: #f2f2f2;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.authBlockWrapper {
  margin-top: 110px;
}

.logo {
  width: 214px;
  height: 48px;
  margin: 0 auto;
}

.globalLinksBlock {
}

.globalLinks {
  display: flex;
  font-family: var(--font-inter);
  color: #676767;

  li {
    margin: 10px;
    font-size: 0.8em;
  }

  li:first-of-type {
    list-style: none;
  }
}

.link {
  color: #787878;
}

.authBlock {
  width: 570px;
  background: #ffffff;
  padding: 40px 55px;
  box-sizing: border-box;
  margin: 60px auto 40px auto;
  box-shadow: 0 3.03601px 10.8537px -4.55402px rgba(0, 0, 0, 0.09);
  font-family: var(--font-inter);
}

