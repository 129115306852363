.errorsBlock {
  color: red;
  font-size: .9em;
  margin-bottom: 25px;
}

.formFieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 30px 0;
}
